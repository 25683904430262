@tailwind components;

@layer components {
  @media only screen and (max-width: 767px) {
    .bookmarks-container {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 2.5rem);
      margin-right: 2.5rem;
      margin-left: 2.5rem;
    }
  }
}
