.link {
    text-decoration: none;
}

.open-menu {
    border: 1px solid rgba(143, 140, 154, 1);
}

.closed-menu {
    border: 1px solid rgba(143, 140, 154, 0);
}