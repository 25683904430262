@tailwind components;

@layer components {
  .map-search-input,
  .map-date-input,
  .map-filter-modal,
  .map-sqft-filter-external,
  .map-more-filters,
  .map-more-filters-external {
    padding-right: 1.125rem;
  }

  .map-filter-modal,
  .map-sqft-filter-external {
    min-width: 15rem;
  }

  .map-date-input {
    min-width: 13rem;
  }

  .map-more-filters,
  .map-more-filters-external {
    min-width: 6.2rem;
  }

  .map-clear-filters {
    max-width: 2.5rem;
  }

  .map-clear-filters:hover > span > a > svg > path {
    fill: rgb(183 183 183 / 1);
  }

  .map-date-input .datepicker-wrapper {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .map-search-input .search-input-wrapper {
    padding-left: 1.125rem;
  }

  .map-search-button .btn-default-outline--default-dark-theme {
    padding-left: 1.125rem !important;
    padding-right: 1.125rem !important;
  }

  /* Large */
  @media only screen and (max-width: 1325px) {
    .map-filter-modal {
      display: none;
    }
  }
  @media only screen and (max-width: 1023px) {
    .map-date-input,
    .map-clear-filters,
    .map-listing-group-button {
      display: none;
    }

    .map-more-filters,
    .map-more-filters-external {
      min-width: 0;
    }
  }

  /* Mobile */
  @media only screen and (max-width: 767px) {
    .map-search-input,
    .map-date-input,
    .map-filter-modal,
    .map-more-filters,
    .map-more-filters-external {
      padding-right: 0.5rem;
    }
    .map-more-filters {
      padding-right: 0;
    }
    .map-search-button {
      margin-left: 0.5rem;
    }
  }

  @media only screen and (max-width: 639px) {
    .map-search-input .search-input-pill p {
      max-width: 16rem;
    }
  }

  @media only screen and (max-width: 499px) {
    .map-search-input .search-input-pill p {
      max-width: 13rem;
    }
  }

  @media only screen and (max-width: 449px) {
    .map-search-input .search-input-pill p {
      max-width: 10rem;
    }
  }

  @media only screen and (max-width: 399px) {
    .map-search-input .search-input-pill p {
      max-width: 8rem;
    }
  }

  @media only screen and (max-width: 319px) {
    .map-search-input .search-input-pill p {
      max-width: 6rem;
    }
  }

  /* min values */
  @media only screen and (min-width: 1024px) {
    .map-search-input .search-input {
      min-width: 18rem;
    }
    .map-search-input .search-input-pill p {
      max-width: 15.75rem;
    }
  }

  @media only screen and (min-width: 1200px) {
    .map-search-input .search-input {
      min-width: 21rem;
    }
    .map-search-input .search-input-pill p {
      max-width: 18.75rem;
    }
  }

  @media only screen and (min-width: 1400px) {
    .map-search-input .search-input {
      min-width: 24rem;
    }
    .map-search-input .search-input-pill p {
      max-width: 21.75rem;
    }
  }
}
