@tailwind components;

@layer components {
  .search-input-wrapper {
    @apply bg-base-white outline-none border-0 w-[100%] h-[3rem] flex items-center justify-between pl-5 pr-2.5 py-3 relative rounded-t-lg;
  }
  .current-location-item {
    @apply bg-slate-400 outline-none text-white-100 cursor-pointer border-cement-100 h-[2.875rem] flex items-start justify-between pl-5 pr-2.5 py-3 relative z-[101] border-t-[#da0000] border-solid;
  }
  .search-input {
    @apply placeholder-black w-[100%] focus-within:placeholder-transparent outline-none text-sm text-[0.765rem] sm:text-[0.875rem];
  }

  .dropdown-list-wrapper {
    @apply absolute w-full py-0 rounded-[20px] outline-none cursor-pointer bg-slate-400 text-white-100 z-[10001] overflow-hidden;
  }
  .dropdown-list-wrapper .overflow-auto {
    @apply py-2 m-0;
  }

  .dropdown-list-wrapper .overflow-hidden-inner-wrapper {
    @apply px-5;
  }
}
