@tailwind components;

@layer components {
  .result-card {
    @apply box-border flex mx-4 mb-4 overflow-hidden border rounded-lg border-cement-300 hover:cursor-pointer;
  }
  .selected {
    @apply border-2 shadow-md border-slate-500;
  }

  .secondary-hover {
    @apply shadow-md;
  }

  .image-coming-soon {
    @apply absolute top-0 z-10 flex items-end justify-center w-full h-full pb-3 bg-cement-100/30 text-white-100;
  }

  .result-card-info-container {
    @apply flex flex-col justify-between p-3 lg:pt-3 lg:pb-4 lg:pl-6 lg:pr-4 w-full lg:w-[60%] lg:h-[9rem] sm:gap-4 md:gap-4 lg:gap-0;
  }
  .tablet-checkbox {
    --tw-border-opacity: 1;
    background-color: rgb(255 255 255 0.8);
    background-color: rgba(255, 255, 255, 0.8);
    border-width: 1px;
    border-radius: 0.125rem;
    border: 1px solid white;
    accent-color: #b16240;
    opacity: 0.8;
  }

  .fav-icon > path {
    @apply stroke-cement-100 lg:stroke-cement-300;
    fill: rgba(255, 255, 255, 0.3);
  }

  .fav-icon:hover > path {
    stroke: #f4aa3b;
    fill: rgba(255, 255, 255, 0.3);
  }

  .font-15 {
    font-size: 15px !important;
  }

  .font-13 {
    font-size: 13px !important;
  }

  .result-address {
    @apply text-cement-500;
  }

  .attributes-container {
    @apply box-border flex flex-row;
  }

  .attributes-container > div {
    @apply flex flex-col;
  }

  .attributes-container > div:first-child {
    @apply md:w-[40%];
  }

  .attributes-divider {
    @apply bg-slate-100 h-full w-[0.0625rem] mx-3;
  }

  .attribute-title {
    @apply text-cement-500;
  }
  .available-time {
    @apply inline pt-1 text-base-black;
  }
  .building-status {
    @apply absolute cursor-pointer bottom-0 z-[9] flex items-center justify-center w-full h-[1.75rem] overflow-hidden text-base-black uppercase;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1250px) {
    .result-card-image {
      @apply !w-[45%];
    }
    .result-card-info-container {
      @apply !w-[55%] pt-4 pb-4 !pl-4 pr-4;
    }
    .available-time {
      width: 85%;
    }
  }

  @media only screen and (max-width: 1023px) {
    .available-time {
      @apply absolute top-[9px] left-[48px] bg-cement-100 p-[5px] rounded font-light;
    }

    .attributes-container > div {
      @apply w-[50%];
    }

    .attributes-container > div:first-child {
      @apply mr-[12px];
    }

    .attributes-container > .attributes-divider {
      @apply hidden w-0;
    }

    .attributes-container .property-item {
      @apply flex flex-row space-x-[8px];
    }
  }

  @media only screen and (max-width: 640px) {
    .card-options {
      @apply w-[94%];
    }
  }
  @media only screen and (min-width: 641px) and (max-width: 1023px) {
    .card-options {
      width: 90%;
    }
  }
  @media only screen and (min-width: 1024px) {
    .card-options {
      @apply w-[96%];
    }
  }
}
