@tailwind components;

/* needed to override inline styles on Mapbox marker */
.listing-map > .mapboxgl-map > .mapboxgl-canvas-container > .mapboxgl-marker {
  @apply !pointer-events-none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
