@tailwind components;

@layer components {
  .on-top {
    z-index: 101 !important;
  }
  .primary-modal-main {
    @apply fixed inset-0 z-[1001] flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none w-screen h-screen;
  }

  .primary-modal-sub {
    @apply fixed left-0 bottom-0 w-[100%] md:relative md:w-auto max-w-3xl mx-auto md:my-6;
  }

  .primary-modal-box {
    @apply px-6 pt-5 pb-12 md:min-w-[30.375rem] border-0 rounded-lg shadow-lg relative flex flex-col  bg-white-400 outline-none focus:outline-none sm:bottom-auto bottom-[-16px];
  }

  .primary-modal-close-btn {
    @apply absolute text-black border-0 opacity-100 hover:opacity-50 right-4;
  }

  .primary-modal-close-btn.white {
    color: white;
  }

  .primary-modal-bg {
    @apply fixed inset-0 z-[71] bg-black opacity-70 !my-0;
  }

  @media only screen and (min-width: 360px) and (max-width: 767px) {
    .media-modal {
      @apply fixed left-0 top-14 w-[100%] md:relative md:w-auto max-w-3xl mx-auto md:my-6;
    }
    .swiper-custom-pagination {
      color: #fff !important;
      font-size: 20px;
      position: absolute;
      top: 10px !important;
      bottom: unset !important;
      left: unset !important;
      width: auto !important;
    }
  }
}
