@tailwind components;

@layer components {
  /* Below css only used for Available SqFt Modal MinMax pills max width */
  @media only screen and (min-width: 360px) and (max-width: 399px) {
    .responsive-sqft-modal-w-more-filters {
      @apply max-w-[3.7rem];
    }
    .responsive-sqft-modal-w-more-filters-external {
      @apply max-w-[10rem];
    }
    .responsive-sqft-modal-w-landing-page {
      @apply max-w-[5rem];
    }
  }
  @media only screen and (min-width: 400px) and (max-width: 449px) {
    .responsive-sqft-modal-w-more-filters {
      @apply max-w-[4.7rem];
    }
    .responsive-sqft-modal-w-more-filters-external {
      @apply max-w-[10rem];
    }
    .responsive-sqft-modal-w-landing-page {
      @apply max-w-[6.2rem];
    }
  }
  @media only screen and (min-width: 450px) and (max-width: 499px) {
    .responsive-sqft-modal-w-more-filters {
      @apply max-w-[6.5rem];
    }
    .responsive-sqft-modal-w-landing-page {
      @apply max-w-[8.8em];
    }
  }
  @media only screen and (min-width: 500px) and (max-width: 549px) {
    .responsive-sqft-modal-w-more-filters {
      @apply max-w-[8.1rem];
    }
    .responsive-sqft-modal-w-landing-page {
      @apply max-w-[1.1rem];
    }
  }
  @media only screen and (min-width: 550px) and (max-width: 599px) {
    .responsive-sqft-modal-w-more-filters {
      @apply max-w-[9.7rem];
    }
    .responsive-sqft-modal-w-landing-page {
      @apply max-w-[2.2rem];
    }
  }
  @media only screen and (min-width: 600px) and (max-width: 649px) {
    .responsive-sqft-modal-w-more-filters {
      @apply max-w-[11.2rem];
    }
    .responsive-sqft-modal-w-landing-page {
      @apply max-w-[1.9rem];
    }
  }
  @media only screen and (min-width: 650px) and (max-width: 699px) {
    .responsive-sqft-modal-w-more-filters {
      @apply max-w-[12.8rem];
    }
    .responsive-sqft-modal-w-landing-page {
      @apply max-w-[2.5rem];
    }
  }
  @media only screen and (min-width: 700px) and (max-width: 749px) {
    .responsive-sqft-modal-w-more-filters {
      @apply max-w-[14.4rem];
    }
    .responsive-sqft-modal-w-landing-page {
      @apply max-w-[2.3rem];
    }
  }
  @media only screen and (min-width: 750px) and (max-width: 767px) {
    .responsive-sqft-modal-w-more-filters {
      @apply max-w-[15.9rem];
    }
    .responsive-sqft-modal-w-landing-page {
      @apply max-w-[2rem];
    }
  }
}
