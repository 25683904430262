@tailwind components;

@layer components {
  @media only screen and (max-width: 767px) {
    .saved-content-page-wrapper > div {
      width: 100%;
      padding-left: 0.6em;
      padding-right: 0.6em;
    }
  }
}
