@tailwind components;

@layer components {
  .thinScrollbar::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  .thinScrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .thinScrollbar::-webkit-scrollbar-thumb {
    background: #888;
  }

  .thinScrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .favorites-table tbody > :not(tr:last-child) > :not(td:first-child):not(td:last-child) {
    @apply border-b border-cement-200;
  }

  .favorites-table tbody div {
    @apply border-none;
  }

  .favorites-table thead tr th div span {
    line-height: unset;
  }

  .favorites-table thead tr th div svg {
    @apply h-[1rem] w-[1rem];
  }

  @media only screen and (max-width: 400px) {
    .favorites-table .mobile-actions button > span {
      padding: 0 10px !important;
    }
  }
  @media only screen and (max-width: 767px) {
    .favorites-table tbody > :not(tr:last-child) > :not(td:first-child):not(td:last-child) {
      border: none;
    }

    .favorites-table tbody {
      background-color: transparent;
    }

    .favorites-table caption {
      display: none;
    }

    .favorites-table thead {
      display: none;
    }

    .favorites-table .table-item {
      display: flex;
      flex-direction: column;
      position: relative;
      padding-bottom: 8px;
      background-color: white;
      margin-bottom: 24px;
      border-radius: 8px;
      border: 1px solid #d3d3d3;
      overflow: hidden;
    }

    .favorites-table .table-item > td {
      padding: 0;
    }

    .favorites-table .table-item .object-cover {
      width: 100%;
      height: 117px;
    }

    .favorites-table .table-item .checkbox {
      position: absolute;
      top: 0.5em;
      left: 0.5em;
      z-index: 12;
      opacity: 0.8;
    }

    .favorites-table .table-item .checkbox svg {
      z-index: 12;
    }

    .name-item-container {
      flex-direction: column;
    }

    .name-item-container .w-14 {
      width: 100%;
    }

    .name-item-container .text-container {
      width: -webkit-fill-available;
      margin: 1rem;
    }

    .name-item-container .text-container h6 {
      text-decoration: none;
    }

    .available-item {
      position: absolute;
      top: calc(117px + 1em);
      right: 1em;
      font-weight: 600;
    }

    .szh-menu-button {
      position: absolute;
      right: 0.5em;
    }

    .szh-menu-button svg {
      stroke: white;
    }

    .collection-name-menu {
      position: initial !important;
    }

    .cell-size,
    .cell-clearHeight,
    .cell-loadingDocks,
    .cell-driveInDoors {
      width: 50%;
      text-align: left;
      border: none;
    }

    .cell-size > a > p,
    .cell-clearHeight > a > p,
    .cell-loadingDocks > a > p,
    .cell-driveInDoors > a > p {
      background-color: #efefef;
      margin-right: 0;
      border: 4px solid white;
      padding: 4px;
      display: flex;
      margin-right: 4px;
      margin-left: 4px;
    }
    .cell-size,
    .cell-loadingDocks {
      margin-left: 8px;
    }

    .cell-clearHeight,
    .cell-driveInDoors {
      margin-top: -36px;
      margin-left: calc(50% - 8px);
    }

    .additional {
      display: inline;
      color: #5c5a66;
    }

    .favorites-table .mobile-actions {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      align-items: center;
      justify-content: center;
      background-color: #efefef;
      height: 90px;
      z-index: 16;
    }

    .favorites-table .mobile-actions .actions {
      border: 1px solid black;
      background-color: #fff;
      color: black;
    }

    .favorites-table .mobile-actions button > span {
      padding: 0 28px;
    }

    .favorites-table .mobile-actions button svg {
      color: black;
    }

    .favorites-table .mobile-actions .group {
      flex: 0 1 40%;
    }

    .favorites-table .mobile-actions .group:last-child {
      flex: 0 1 100%;
    }

    .cell-more-actions .szh-menu-container {
      position: absolute;
      z-index: 101;
      top: 24px;
      right: 32px;
    }
    .building-status-favorite {
      @apply absolute cursor-pointer bottom-0  flex items-center justify-center w-full h-[1.75rem] overflow-hidden bg-site-100 text-base-black uppercase;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .custom-width-tablet {
      max-width: 16rem;
      white-space: normal;
    }

    .szh-menu-button {
      top: 0.75em;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .custom-width-tablet {
      max-width: 8rem;
      white-space: normal;
    }
    .cell-more-actions .szh-menu-container {
      position: fixed !important;
    }
  }
  @media only screen and (min-width: 1024px) {
    .custom-width-tablet {
      max-width: 11rem;
      white-space: normal;
    }
  }
  @media only screen and (min-width: 768px) {
    .main-div {
      overflow-y: scroll;
      height: 350px;
    }
  }
}
