@tailwind components;

@layer components {
  .react-multi-email > input {
    @apply bg-cement-100 !h-full !px-[0.75rem];
  }

  .react-multi-email [data-tag]:first-child {
    margin-left: 16px;
  }

  .multiple-email {
    width: inherit;
  }

  .react-multi-email [data-tag] {
    @apply bg-cement-600 py-[0.50em] px-[1em];
  }

  .react-multi-email > input:focus,
  .react-multi-email > input:active {
    @apply !bg-base-white;
  }
}
