@tailwind components;

@layer components {
  .more-filers-modal .overflow-auto {
    max-height: 54vh;
  }

  .more-date-input {
    padding-bottom: 1rem;
  }

  .more-filers-modal .datepicker-dropdown {
    @apply md:top-[3rem];
  }

  @media only screen and (max-width: 1023px) {
    .more-filers-modal .overflow-auto {
      height: calc(100vh - 16.8rem);
      max-height: calc(100vh - 14rem);
    }
  }

  @media only screen and (max-width: 767px) {
    .more-filers-modal {
      top: 0;
    }
    .more-filers-modal .overflow-auto {
      height: calc(100vh - 8.2rem);
      max-height: calc(100vh - 9.4rem);
    }
    .more-filers-modal-header {
      height: 4.6rem;
    }
    .last-filter {
      @apply pb-24;
    }
  }

  @media only screen and (max-height: 668px) {
    .more-filers-modal .overflow-auto {
      height: calc(100vh - 14.4rem);
      max-height: calc(100vh - 14.4rem);
    }
  }

  @media only screen and (min-height: 768px) and (min-width: 1024px) and (orientation: landscape) {
    .more-filter-modal-container {
      height: calc(100vh - 380px) !important;
    }
  }
}
