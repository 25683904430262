@tailwind components;

@layer components {
  .btn {
    @apply border disabled:cursor-not-allowed flex flex-row space-x-[0.6875rem] h-12 items-center justify-center px-5 py-3 rounded-lg text-sm;
  }

  /* Reveal action buttons' classes */
  .btn-reveal-action--base {
    @apply h-12 py-3 space-x-0 sm:pl-3 md:p-3 md:rounded-lg disabled:cursor-not-allowed md:bg-cement-600 sm:bg-transparent;
  }

  /* require multiple transitions properties to handle different mouseover and mouseout transition durations */
  .btn-reveal-action--default {
    @apply btn-reveal-action--base flex md:hover:!bg-base-black md:hover:gap-[0.6875rem] md:hover:!text-white-100 md:min-w-[2.5rem];
    transition: gap 400ms;
  }

  .btn-reveal-action--default:hover {
    transition: gap 100ms;
  }

  /* require multiple transitions properties to handle different mouseover and mouseout transition durations */
  .btn-reveal-action--default > span {
    @apply inline-block md:group-hover:max-w-[12rem] md:group-hover:text-white-100 overflow-hidden align-top max-w-0 text-transparent whitespace-nowrap;
    transition: max-width 400ms;
  }

  .btn-reveal-action--default:hover > span {
    transition: max-width 700ms;
  }

  .btn-reveal-action--disabled {
    @apply btn-reveal-action--base text-cement-200;
  }

  .btn-reveal-action--disabled > span {
    @apply hidden align-top whitespace-nowrap;
  }
  @media (hover: none) {
    .btn-reveal-action--default:hover {
      @apply !bg-base-white !text-base-black;
    }
  }
  /* Default outline button classes */
  .btn-default-outline--default {
    @apply btn text-slate-500 bg-cement-600 hover:text-white-100 hover:bg-slate-500 hover:border-slate-500;
  }

  .btn-default-outline--default-dark-theme {
    @apply bg-transparent btn border-cement-100 hover:bg-white-100 text-cement-100 hover:text-slate-500;
  }

  .btn-default-outline--disabled {
    @apply btn border-cement-200 text-cement-200 bg-base-white;
  }

  .btn-default-outline--disabled-dark-theme {
    @apply bg-transparent btn border-white-100 text-white-100;
  }

  .btn-default-outline--error {
    @apply btn border-freight-200 text-freight-200;
  }

  .btn-default-outline--error-dark-theme {
    @apply btn-default-outline--error !bg-transparent;
  }

  /* No fill button classes */
  .btn-no-fill--base {
    @apply border-transparent btn !px-0;
  }

  .btn-no-fill--default {
    @apply active:text-site-300 btn-no-fill--base hover:text-rust-100 text-slate-500;
  }

  .btn-no-fill--default-white {
    @apply active:text-site-300 btn-no-fill--base hover:text-slate-500 text-white-100;
  }

  .btn-no-fill--disabled {
    @apply btn-no-fill--base text-cement-200;
  }

  .btn-no-fill--disabled-white {
    @apply btn-no-fill--base text-cement-300;
  }

  .btn-no-fill--error {
    @apply btn-no-fill--base text-freight-200;
  }

  /* Primary 1 button classes */
  .btn-primary-one--default {
    @apply bg-slate-500 btn text-white-100 hover:bg-base-black;
  }

  .btn-primary-one--disabled {
    @apply opacity-50 btn-primary-one--default;
  }

  /* Primary 2 button classes */
  .btn-primary-two--default {
    @apply btn text-slate-500 !rounded-full hover:text-white-100 hover:bg-freight-200 hover:border-freight-200 bg-freight-100;
  }

  .btn-primary-two--disabled {
    @apply opacity-50 btn-primary-two--default;
  }

  /* report button  classes */

  .btn-report--default {
    @apply w-full border-0 btn text-slate-500 hover:text-white-100 hover:bg-freight-200 bg-freight-100;
  }

  .btn-report--disabled {
    @apply w-full border-0 opacity-50 btn-primary-two--default;
  }

  /* Save Search button classes */
  .btn-save-search--default {
    @apply bg-transparent border-slate-500 btn text-slate-500 hover:border-freight-100 hover:text-slate-500 rounded-3xl;
  }

  /*Mobile Action button classes */
  .btn-mobile-action--default {
    @apply w-6 h-6;
  }
}
