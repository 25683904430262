@tailwind components;

@layer components {
  .toggle-switch-container {
    @apply bg-cement-200 cursor-pointer duration-100 ease-in-out flex h-6 items-center rounded-full p-1 w-[2.625rem];
  }

  .toggle-switch-container--disabled {
    @apply !bg-cement-100/50 cursor-not-allowed;
  }

  .toggle-switch-container--toggled--disabled {
    @apply !bg-freight-100/50 cursor-not-allowed;
  }

  .toggle-switch {
    @apply bg-white-100 duration-100 ease-in-out h-[1.125rem] rounded-full transform w-[1.125rem];
  }
}
