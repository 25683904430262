@tailwind components;

@layer components {
  .map-pin-popup .mapboxgl-popup-tip {
    @apply visible;
  }

  .map-pin-popup .mapboxgl-popup-content {
    @apply p-0;
  }    
}