@tailwind components;

@layer components {
  .hide-desktop {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    .mobile-actions {
      display: flex;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 8px;
      align-items: center;
      justify-content: center;
      background-color: #efefef;
      height: 90px;
      z-index: 15;
    }

    .mobile-actions .group {
      flex: 0 1 100%;
    }

    .mobile-actions button.cancel {
      background-color: #efefef;
      border: none;
      @apply mr-4;
    }

    .mobile-actions button {
      border-color: rgb(92 90 102 / 1);
      width: max-content;
      @apply ml-2;
    }

    .mobile-actions button svg {
      color: black;
    }
    .szh-menu-container {
      z-index: 1200;
    }
  }
}
