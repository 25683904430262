.heading-text-wrapper {
  font-family: var(--font-family-inter-tight);
  font-weight: 600;
  color: var(--PrimarySlate);
  font-size: 32px;
  line-height: 38px;
}

.listing-details {
  display: flex;
  flex-direction: column;
  width: 65%;
  gap: 4px;
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  padding: 5px 20px;
  /* background: rgba(149, 182, 192); */
}

.form-container {
  display: flex;
  flex-direction: column;
  max-width: 840px;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
}

.name-text-wrapper {
  font-size: larger;
}

.iframe-loader {
  position: absolute;
  left: 50%;
  top: 25%;
  z-index: 2;
}

#pardot-form-iframe {
  /* z-index: 3; */
  position: relative;
  /* background: rgba(149, 182, 192); */
}
.iframe-container {
  max-height: 80%;
  height: 80%;
  padding: 5px 20px;
  position: relative;
  background: rgba(149, 182, 192);
}

@media (max-width: 768px) {
  .heading-text-wrapper {
    font-size: 26px;
    line-height: 26px;
  }
  .name-text-wrapper {
    font-size: 16px;
    font-weight: 500;
  }
  .address-text-wrapper {
    font-size: 15px;
  }
  .iframe-container {
    max-height: 85%;
    height: 85%;
    background: rgba(149, 182, 192);
    padding: 5px 20px;
    z-index: 1;
  }
  .frame {
    padding: 5px 20px;
    margin-top: 0.5rem;
  }
  .iframe-loader {
    left: 40%;
  }
}
