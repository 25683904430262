@tailwind components;

@layer components {
  /*  large */
  nav {
    height: 52px;
  }

  nav .brand {
    height: 1.75rem;
    margin-left: 0.25rem;
  }

  nav .brand-mobile {
    display: none;
  }

  nav .nav-right {
    margin-right: 1.5rem;
  }

  @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  .ll-header header {
    position: relative !important;
  }

  .smoke-test-banner {
    /* This section calls the slideInFromTop animation we defined above */
    animation: 1s ease-in 0s 1 slideInFromTop;

    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 10px;
    background: #252429;
    border-radius: 6px;
    max-width: 190px;
    color: white;
  }

  /*  medium */
  @media only screen and (max-width: 1024px) {
    nav {
      height: 52px;
    }

    nav .brand {
      height: 1.75rem;
      margin-left: 0.25rem;
    }

    nav .nav-right {
      margin-right: 0.7rem;
    }
  }

  /*  small */
  @media only screen and (max-width: 767px) {
    nav {
      height: 52px;
    }

    nav .brand {
      display: none;
    }

    nav .brand-mobile {
      height: 1.6rem;
      margin-left: 1.5rem;
      display: block;
    }

    nav .nav-right {
      margin-right: 1.5rem;
    }

    nav .nav-items {
      display: none;
    }
  }
}

.font-body {
  font-family: 'Inter Tight', sans-serif;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-10 {
  font-size: 10px;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-family: 'Inter Tight', sans-serif;
  -webkit-font-smoothing: antialiased;
}

@media (min-width: 400px) {
  .container {
    max-width: 400px;
  }
}

@media (min-width: 500px) {
  .container {
    max-width: 500px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1140px) {
  .container {
    max-width: 1140px;
  }
}

.bg-solar {
  --tw-bg-opacity: 1;
  background-color: rgba(150, 183, 192, var(--tw-bg-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.rounded-br-20 {
  border-bottom-right-radius: 20px;
}

.shadow-nav {
  --tw-shadow: 2px 3px 4px 0px #00000040;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}

.link-hamburger:before {
  content: '';
}

/* Add these new styles */
.dropdown-content {
  transition: opacity 0.2s ease-out;
}

.dropdown-content.hidden {
  pointer-events: none;
}

.submenu {
  position: absolute;
  left: 100%;
  top: 0;
  background: #cadadf;
  padding: 1rem;
  border-radius: 0.5rem;
  min-width: 170px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.submenu a {
  padding: 0.5rem 0;
  display: block;
}

.dropdown-container {
  margin-top: 18px;
  padding-bottom: 18px;
}

.dropdown-container:hover .dropdown-content,
.dropdown-content:hover {
  display: block;
}

.dropdown-content {
  display: none;
}
