.mapboxgl-ctrl-bottom-left {
    display: none;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .population-legend-horizontal {
        bottom: 7rem;
      }
}

/* For Android Devices */
@media only screen and (min-height: 650px) and (max-height: 700px) {
  .population-legend-vertical {
    bottom: 8rem !important;
  }
}
@media only screen and (min-height: 700px) and (max-height: 800px) {
  .population-legend-vertical {
    bottom: 9rem !important;
  }
}
@media only screen and (min-height: 800px) {
  .population-legend-vertical {
    bottom: 9.5rem !important;
  }
}

/* For IOS Devices */
@media only screen and (min-height: 650px) {
    .population-legend-vertical-ios {
      bottom: 9.5rem !important;
    }
}
