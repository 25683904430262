html {
  font-size: 100%;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@100;300;400;500;600;700&display=swap');
body {
  font-family: 'Inter Tight', sans-serif;
}

#root {
  height: -webkit-fill-available;
}

.selectedItem {
  background-color: #898989;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  background: #d6d6d6;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2.8125rem;
  border-radius: 2.8125rem;
  background: #acacac;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #acacac;
}

@media only screen and (max-width: 767px) {
  .page {
    overflow-x: hidden;
  }

  html,
  body {
    max-width: 100%;
    -webkit-overflow-scrolling: auto;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: -webkit-fill-available;
  }
}
